import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL
const apiKey = process.env.REACT_APP_API_KEY

export function getTenantList(parameters){
    return axios.get(baseURL+'Tenants?Search='+parameters.searchText+'&pageSize='+parameters.pageSize+'&PageNumber='+parameters.PageNumber,{
      headers: {
          'Content-Type': 'application/json',
          'authorization' : apiKey
      }
  })
};

export function getSelectedTenant(id){
  return axios.get(baseURL+'tenants/'+id,{
    headers: {
        'Content-Type': 'application/json',
        'authorization' : apiKey
    }
  })
};

// export function getSearchTenantList(parameters){
//   return axios.get(api+'Tenants?Search='+parameters.searchText+'&pageSize='+parameters.pageSize+'&PageNumber='+parameters.PageNumber)
// }

export function addNewBooking(data){
  const parameters = JSON.stringify(data)
  // console.log(parameters)
  // console.log(data)
  return axios.post(baseURL+'bookings', parameters, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      'Content-Type': 'application/json',
      'authorization' : apiKey
    }
  })
}

export function getTenantServices(id){
  return axios.get(baseURL+'tenants/services/'+id,{
      headers: {
          'Content-Type': 'application/json',
          'authorization' : apiKey
      }
  })
};

export function getTenantServiceArea(id){
  return axios.get(baseURL+'tenants/serviceAreas/'+id,{
      headers: {
          'Content-Type': 'application/json',
          'authorization' : apiKey
      }
  })
};


export function getTenantOpeningHoursRequest(id){
  return axios.get(baseURL+'tenants/openingHours/'+id, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : apiKey
      }
  })
}