import React, { Component } from 'react';
// import BootstrapModal from 'react-bootstrap/cjs/Modal';
import Alert from 'react-bootstrap/cjs/Alert'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button from '../../form/button/Button'
import Form from 'react-bootstrap/cjs/Form'
import TextField from '../../form/textField/TextField'
import "../../layout/Layout.css";
import DatePicker from '../../form/datePicker/datePicker';
import moment from 'moment';
import * as selectors from '../../../store/Selector';
import * as actions from '../../../store/Action';
// import Loader from './Loader';
// import ConfirmModal from '../layout/ConfirmModal'

export class Modal extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            vehicalRegNo: '',
            name: '',
            email: '',
            phoneNumber: '',
            date: '',
            massage: '',
            isDateValidate: false,
            isModalOpen:true,
            isConfirmDetails: false,
            isGetReffererDetails: true,
            isSubmitButtonShow: true,
        };
        // this.confirmDetails = this.confirmDetails.bind(this);
        // this.vehicalRegNoChange = this.vehicalRegNoChange.bind(this);
      };

    handleModalClose=()=>{
        this.setState ({
            isModalOpen: false,
            isConfirmDetails: false,
            isSubmitButtonShow: true,
            isGetReffererDetails: true,
            // isErrorDate:false
        })
    }

    handleModalReturn=()=>{
        this.setState ({
            isGetReffererDetails:true,
            isConfirmDetails: false
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeDate  = (date) => {
        // console.log(date)
        this.setState({date:date})
    }

    confirmDetails=()=>{
        this.setState ({isGetReffererDetails:false, isConfirmDetails: true})
    }

    submitReferrerDetails =(data)=>{
        const { match: { params }  } = this.props;
        // const selectedDate = data.date.getFullYear(data.date)+'-'+(data.date.getMonth(data.date)+1)+'-'+(data.date.getDate(data.date))
        // console.log(parms)
        // console.log(selectedTenantId ? selectedTenantId : params.id )
        const date = moment(data.date).format("YYYY-MM-DD");
        const time_ = new Date(data.date.getFullYear(data.date),(data.date.getMonth(data.date)),(data.date.getDate(data.date)),8,30,0)
        const time = moment(time_).format('HH:mm');
        const selectedDate = date+"T"+time
        const parameters = {
            tenantId : parseInt(params.id),
            vehicleRegistrationNumber: data.vehicalRegNo,
            RequestedDate: selectedDate,
            PlanStartDate: selectedDate,
            PlanEndDate: selectedDate,
            DropOffDate: selectedDate,
            PickUpDate: selectedDate,
            CustomerName: data.name,
            CustomerContactName: data.name,
            CustomerEmail: data.email,
            CustomerPhone: data.phoneNumber,
            Notes: data.massage,
            Status:"50",
            ObjectId:"BOKING",
            Channel:params.channel
        }

        this.props.dispatch(actions.sendAddNewBookingRequest(parameters));
        this.setState ({isSubmitButtonShow:false})
    }
  
    render() {
        const {
            vehicalRegNo,
            name,
            email,
            phoneNumber,
            date,
            isDateValidate
        } = this.state;

        const { isError } = this.props;

        return (
            <div>
                {/* <BootstrapModal 
                    show ={this.state.isModalOpen} 
                    onHide={this.handleModalClose}
                    size="md"
                    // dialogClassName="dialog"
                    // style={{width:'90vw'}}
                >
                    <BootstrapModal.Header>
                        <BootstrapModal.Title style={{textAlign:'center'}}>Book appointment</BootstrapModal.Title>
                    </BootstrapModal.Header> */}
                
                    { this.state.isGetReffererDetails ?
                        <div>
                            {/* <BootstrapModal.Body style={{maxHeight: 'calc(100vh - 180px)', overflowY: 'auto'}}> */}
                                <Form className="details-form" style={{padding:'0 30px'}}> 
                                <h2 style={{textAlign:'center',margin:'10px', color:'#4278b7'}}>Book appointment</h2>
                                <div style={{borderTop:'2px solid #4278b7', margin:'10px 0 30px 0'}}></div>
                                    <TextField
                                        controlId="vehicalRegNo"
                                        text="Vehicle Registration No"
                                        placeholder="Enter Vehicle Registration No"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.vehicalRegNo}
                                    />
                                    <TextField
                                        controlId="name"
                                        text="Name"
                                        placeholder="Enter Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.name}
                                    />
                                    <TextField
                                        controlId="email"
                                        text="Email address"
                                        placeholder="Enter Email"
                                        type="email"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.email}
                                    />
                                    <TextField
                                        controlId="phoneNumber"
                                        text="Phone Number"
                                        placeholder="Enter Phone Number"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.phoneNumber}
                                    />
                                    {/* <TextField
                                        controlId="date"
                                        text={isDateValidate ? <div>Select Date <small style={{color:'red'}}> * Enter a valid date</small></div>: "Select Date"}
                                        type="date"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.date}
                                    /> */}
                                    <DatePicker
                                        controlId="date"
                                        text={isDateValidate ? <div>Select Date <small style={{color:'red'}}> * Enter a valid date</small></div>: "Select Date"}
                                        onChange={this.handleChangeDate}
                                        value={this.state.date}
                                        
                                    />
                                    <TextField
                                        controlId="massage"
                                        text="Message to mechanic"
                                        placeholder="Message to mechanic"
                                        type="text"
                                        as="textarea" 
                                        rows={3}
                                        onChange={this.handleChange}
                                        defaultValue={this.state.massage}
                                    />
                                </Form>
                            {/* </BootstrapModal.Body>
                        
                            <BootstrapModal.Footer style={{justifyContent:'center'}}> */}
                                <div style={{display:'flex',justifyContent:'center'}}>
                                <Button 
                                    className="button"
                                    type="submit" 
                                    onClick={this.confirmDetails}
                                    variant="primary" 
                                    text ='Confirm'
                                    disabled={
                                        (name === ''||
                                        date === '' ||
                                        date === null ||
                                        vehicalRegNo === '' ||
                                        email === ''||
                                        phoneNumber === '') && 
                                        (!isDateValidate)
                                    }
                                     
                                />
                                {/* <Button 
                                    variant="secondary"
                                    text="Close"
                                    onClick={this.handleModalClose}
                                /> */}
                                </div>
                            {/* </BootstrapModal.Footer> */}
                        </div>  : ''
                    }
                    {this.state.isConfirmDetails ?
                        <div>
                            {/* <BootstrapModal.Body> */}
                            <h2 style={{textAlign:'center',margin:'40px 10px 10px 10px', color:'#4278b7'}}>Book appointment</h2>
                                <div style={{borderTop:'2px solid #4278b7', margin:'10px 20px 30px 20px'}}></div>
                                <div style={{justifyContent:'center',display:'flex'}}>
                                    <div className="confirm-title">
                                        <p>Vehicle RegNo</p>
                                        <p>Name</p>
                                        <p>Email</p>
                                        <p>Phone Number</p>
                                        <p>Date</p>
                                    </div>
                                    <div className="confirm-details">
                                        <p>: {this.state.vehicalRegNo} </p>
                                        <p>: {this.state.name}</p>
                                        <p>: {this.state.email}</p>
                                        <p>: {this.state.phoneNumber}</p>
                                        <p>: {this.state.date.getFullYear(this.state.date)+'-'+(this.state.date.getMonth(this.state.date)+1)+'-'+(this.state.date.getDate(this.state.date))}</p>
                                    </div>
                                </div>
                            {/* </BootstrapModal.Body> */}
                        
                            {this.state.isSubmitButtonShow ? 
                                <div>
                                    {/* <BootstrapModal.Footer style={{justifyContent:'center'}}> */}
                                    <div style={{display:'flex',justifyContent:'center', marginTop:'30px'}}>
                                        <Button 
                                            variant="primary" 
                                            text="Submit"
                                            onClick={()=>this.submitReferrerDetails(this.state)}
                                        />
                                        <Button 
                                            variant="secondary"
                                            text="Return"
                                            onClick={this.handleModalReturn}
                                            />
                                    {/* </BootstrapModal.Footer> */}
                                    </div>
                                </div> : 
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    {/* <BootstrapModal.Footer style={{justifyContent:'center'}}> */}
                                    {isError ? 
                                        <Alert variant="danger">
                                            Error ! Booking request was not submitted.
                                        </Alert> :
                                        <Alert variant="success">
                                            Success ! Booking request was submitted.
                                        </Alert>}
                                    {/* </BootstrapModal.Footer> */}
                                </div>
                            }
                        </div> : ''
                    }
            {/* </BootstrapModal> */}

            {/* {isConfirmModalOpen ? 
                <ConfirmModal
                    isModalOpen={isConfirmModalOpen}
                /> : ''} */}
        </div>
        )
    }
}

Modal.propTypes = {
    isModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.any,
    confirmDetails: PropTypes.any,
    isGetReffererDetails: PropTypes.any,
    isConfirmDetails: PropTypes.any,
    handleModalReturn: PropTypes.any,
    isSubmitButtonShow: PropTypes.any,
    submitReferrerDetails: PropTypes.any,
    isError: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    // isErrorDate: PropTypes.bool
}

const mapStateToProps = createStructuredSelector({
    isError: selectors.makeAddNewBookingError()
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(Modal));