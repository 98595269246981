import React,{Component} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import File from 'react-bootstrap/cjs/FormFile';
// import { useParams } from 'react-router-dom';
import * as selectors from '../../store/Selector';
import * as actions from '../../store/Action';
// import { Image, Jumbotron } from 'react-bootstrap'
// import Rating from '@material-ui/lab/Rating';
import { MapContainer } from '../layout/MapContainer';
import Vemas from '../../images/findamachanic_banner.jpeg'
// import User from '../../images/user.png'
// import {
//     Facebook, 
// //    Instagram,
//     MailOutlineOutlined,
//     Twitter,
//     Web,
// } from '@material-ui/icons';
// import Button from '../form/button/Button'
import Modal from '../layout/Modal';
import Loader from '../layout/Loader';
import './page.css';
// import Card from '../layout/Cards';
import {
    Card,
} from 'react-bootstrap';
import moment from 'moment';
// import SearchField from '../layout/SearchField';
// import Avatar from 'react-avatar';
import image from '../../images/image1.jpg'
// import { Image } from 'react-bootstrap';
import {
//     Facebook, 
//     Web,
//     MailOutlineOutlined,
//     Twitter,
LocationOn
} from '@material-ui/icons';
import Button from '../form/button/Button'
import { formatNumber } from '../common';

class TenantDetails extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isModalOpen: false,
            isGetReffererDetails: true,
            isConfirmDetails: false,
            isSubmitButtonShow: true,
            filteredTenantList:[],
            filteredSelectedTenantId:{},
            initial:true
            // isErrorDate:false
        };
        this.openBooking = this.openBooking.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this)
        this.confirmDetails = this.confirmDetails.bind(this)
        this.handleModalReturn = this.handleModalReturn.bind(this)
        this.submitReferrerDetails = this.submitReferrerDetails.bind(this)
      };

    //   static getDerivedStateFromProps(prevProps,prevState) {
    //     const {tenantAreasLoading ,tenantServicesLoading,tenantServices,tenantAreas  } = prevProps;
    //     const {initial} = prevState;

    //     if( initial )

    //     if (tenantList  && tenantList.length !== 0 && selectedTenant){
    //         let newTenantList = [];
    //         for (let i = 0; i < tenantList.length; i++) {
    //             if (tenantList[i].id !== parseInt(selectedTenant.id)) {
    //                 newTenantList.push(tenantList[i]);
    //             }
    //         }
    //         return {filteredTenantList:newTenantList}
              
    //     } 
    //     return null
    //   }

    componentDidMount(){
        const { match: { params }, selectedTenantId  } = this.props;
        // const postalCode = window.localStorage.getItem('postalCode')
        // const parameters = {
        //     pageSize: 5,
        //     PageNumber: 1,
        //     searchText:postalCode
        //  }

        // this.props.dispatch(actions.loadTenantListRequest(parameters));
        if(selectedTenantId.id){
            this.props.dispatch(actions.loadSelectedTenantRequest(selectedTenantId.id));
            this.props.dispatch(actions.loadTenantServicesAreaRequest(selectedTenantId.id));
            this.props.dispatch(actions.loadTenantServicesRequest(selectedTenantId.id));
            this.props.dispatch(actions.loadTenantOpenHoursRequest(selectedTenantId.id));
            this.props.dispatch(actions.loadTenantServicesAreaLoading(true));
            this.props.dispatch(actions.loadTenantServicesLoading(true));
        }else
            this.props.dispatch(actions.loadSelectedTenantRequest(params.id));
            this.props.dispatch(actions.loadTenantServicesAreaRequest(params.id));
            this.props.dispatch(actions.loadTenantServicesRequest(params.id));
            this.props.dispatch(actions.loadTenantOpenHoursRequest(params.id));
            this.props.dispatch(actions.loadTenantServicesAreaLoading(true));
            this.props.dispatch(actions.loadTenantServicesLoading(true));
    }

    openBooking(){
        this.setState ({isModalOpen:true})
    }

    handleModalClose(){
        this.setState ({
            isModalOpen: false,
            isConfirmDetails: false,
            isSubmitButtonShow: true,
            isGetReffererDetails: true,
            // isErrorDate:false
        })
    }

    handleModalReturn(){
        this.setState ({
            isGetReffererDetails:true,
            isConfirmDetails: false
        })
    }

    confirmDetails(){
        // const today = new Date()
        // // const formatted_date = today.getFullYear(today)+'-'+(today.getMonth(today)+1)+'-'+(today.getDate(today))
        // const current_date = new Date(date)
        // const selectedDate = new Date(date)
        // if(current_date > selectedDate){
        //     this.setState ({isErrorDate:true})
        // }

        // else 
        this.setState ({isGetReffererDetails:false, isConfirmDetails: true})
    }

    submitReferrerDetails =(data)=>{
        const { match: { params }, selectedTenantId  } = this.props;
        // const selectedDate = data.date.getFullYear(data.date)+'-'+(data.date.getMonth(data.date)+1)+'-'+(data.date.getDate(data.date))
        // console.log(parms)
        // console.log(selectedTenantId ? selectedTenantId : params.id )
        const date = moment(data.date).format("YYYY-MM-DD");
        const time_ = new Date(data.date.getFullYear(data.date),(data.date.getMonth(data.date)),(data.date.getDate(data.date)),8,30,0)
        const time = moment(time_).format('HH:mm');
        const selectedDate = date+"T"+time
        const parameters = {
            tenantId : parseInt(selectedTenantId.id ? selectedTenantId.id : params.id),
            vehicleRegistrationNumber: data.vehicalRegNo,
            RequestedDate: selectedDate,
            PlanStartDate: selectedDate,
            PlanEndDate: selectedDate,
            DropOffDate: selectedDate,
            PickUpDate: selectedDate,
            CustomerName: data.name,
            CustomerContactName: data.name,
            CustomerEmail: data.email,
            CustomerPhone: data.phoneNumber,
            Notes: data.massage,
            Status:"50",
            ObjectId:"BOKING",
            Channel:'02'
        }

        this.props.dispatch(actions.sendAddNewBookingRequest(parameters));
        this.setState ({isSubmitButtonShow:false})
    }

    handleTenantDetails = (tenant) => { 
        this.setState({filteredSelectedTenantId:tenant})
        window.localStorage.setItem('postalCode', tenant.billPostalCode)
    }

    componentDidUpdate=(prevProps, prevState)=> {
        const {filteredSelectedTenantId} =this.state;
        const { match: { params }  } = prevProps;
        const postalCode = window.localStorage.getItem('postalCode')
        const parameters = {
            pageSize: 5,
            PageNumber: 1,
            searchText: postalCode
        }

        if(prevState.filteredSelectedTenantId.id !== filteredSelectedTenantId.id){
            this.props.history.push("/tenantDetails/" + filteredSelectedTenantId.id)
            this.props.dispatch(actions.loadSelectedTenantRequest(filteredSelectedTenantId.id));
            this.props.dispatch(actions.loadTenantListRequest(parameters));
        } 
        
        if(prevState.filteredSelectedTenantId.id === filteredSelectedTenantId.id && params.id !== this.props.match.params.id){
            this.props.history.push("/tenantDetails/" + this.props.match.params.id)
            this.props.dispatch(actions.loadSelectedTenantRequest(this.props.match.params.id));
            this.props.dispatch(actions.loadTenantListRequest(parameters));
        }

        
    }

    openInNewTab = (url)=>{
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    
    render(){
        const {
            isModalOpen,
            isGetReffererDetails,
            isConfirmDetails,
            isSubmitButtonShow,
            // filteredTenantList,
            // isErrorDate
        } = this.state;

        const { 
            selectedTenant, 
            loading, 
            isError,
            isSubmitting,
            tenantAreas,
            tenantServices,
            tenantOpenHours
        } = this.props;
    
        return (
            <div>
                {loading && <Loader/>}
                {!loading && 
                <div >
                    <div style={{ marginTop:30}}>
                        <div>
                            {/* <Card
                                tenants={selectedTenant}
                                handleTenantDetails={this.openBooking}
                            /> */}
                            
                            
                            <div className='booking-button'>
                                <div className='booking-open-button'>
                                    <Button 
                                        onClick={() => {this.openBooking(selectedTenant)}}
                                        variant="primary" 
                                        text ={selectedTenant && selectedTenant.expireStatus ? 'Inquiry' :'Book Online'}
                                    />
                                </div>
                            </div>
                            <div className="card-top-tenant">
                                <div className="tenant-logo" >
                                    <Card.Img src={selectedTenant && selectedTenant.logo ? selectedTenant.logo : image} />
                                    {/* <Image id='tenant-logo-card' rounded src={selectedTenant && selectedTenant.logo ? selectedTenant.logo : image} /> */}
                                </div>
                                <div className='tenant-details-card'>
                                    <div>
                                        {/* <div style={{marginRight:'10px'}}>
                                            <Avatar name={selectedTenant.businessName} size={40} round="50%" maxInitials={2}/>
                                        </div> */}
                                        <h5 className="tenant-businessName">{selectedTenant.businessName}</h5>
                                    </div>
                                    <div style={{margin:'5px 0'}}>
                                        <span>{selectedTenant.billStreetNumber}, {selectedTenant.billStreetName}</span> <br/>
                                        <span>{selectedTenant.billCity}, {selectedTenant.billPostalCode}, {selectedTenant.billRegion}</span>
                                    </div>
                                    <div style={{margin:'5px 0 10px 0'}}>
                                        <small>Phone : <a href={selectedTenant.phoneNumber} style={{color:'#000000'}}>{selectedTenant && selectedTenant.phoneNumber ? formatNumber(selectedTenant.phoneNumber) : ''}</a></small>
                                    </div>
                                </div>
                                {/* <div className='tenant-button-card' >
                                    <Button 
                                        onClick={() => {this.openBooking(selectedTenant)}}
                                        variant="primary" 
                                        text ='Book Online'
                                    />
                                </div> */}
                            </div>
                            <div className='banner-top'></div>
                            <img alt="banner" id="banner" src={ selectedTenant && selectedTenant.bannerImage ? selectedTenant.bannerImage : Vemas}/>
                            <File id="userPhotoUpload" hidden ref={photoUpload => this.photoUpload = photoUpload} />
                            
                        <div>
                        {tenantServices && tenantServices.length && tenantServices.length > 0 ? 
                                <div className="title-area">
                                    <h2>OUR SERVICES</h2>
                                </div>:''}
                            <div className="service-list">
                                {tenantServices && tenantServices.map(service => (
                                    <div>
                                        <div className="card-content">
                                            <h4>{service.title}</h4>
                                            <span>
                                            {service.description}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {tenantAreas && tenantAreas.length && tenantAreas.length > 0 ? 
                                <div className="title-area">
                                    <h2>SERVICING AREAS</h2>
                                </div>:''}
                            
                        </div>

                        </div>
                        
                    </div>
                    <div className="container" style={{ marginBottom:50}}>
                            <div className="area-list">
                                {tenantAreas && tenantAreas.map(area => (
                                    <div>
                                        <div className="card-content-location">
                                            <div id='location-icon'>
                                                <LocationOn/>
                                                {/* <ui5-icon class="samples-margin" name="map" id="location-icon"></ui5-icon> */}
                                            </div>
                                            <div>
                                                {/* <span>{data.streetNo}, {data.streetName}</span>  */}
                                                <span>{area.cityName}, {area.postalCode}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                    </div>

                    <div className="title-area">
                            <h2>OPENING HOURS</h2>
                        </div>
                        <div className="container" style={{ marginBottom:50, marginTop:'50px',textAlign:'center'}}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            Day 
                                        </th>
                                        <th>
                                            Status 
                                        </th>
                                        <th>
                                            Start Time
                                        </th>
                                        <th>
                                            End Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Monday
                                        </td>
                                        <td>
                                            {
                                                tenantOpenHours && tenantOpenHours.monStatus ? 
                                                <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                            }
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.monStatus && tenantOpenHours.monStart}
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.monStatus && tenantOpenHours.monEnd}    
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Tuesday
                                        </td>
                                        <td>
                                            {
                                                tenantOpenHours && tenantOpenHours.tueStatus ? 
                                                <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                            }
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.tueStatus && tenantOpenHours.tueStart}
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.tueStatus && tenantOpenHours.tueEnd}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Wednesday
                                        </td>
                                        <td>
                                            {
                                                tenantOpenHours && tenantOpenHours.wedStatus ? 
                                                <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                            }
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.wedStatus && tenantOpenHours.wedStart}
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.wedStatus && tenantOpenHours.wedEnd}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Thursday
                                        </td>
                                        <td>
                                            {
                                                tenantOpenHours && tenantOpenHours.thuStatus ? 
                                                <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                            }
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.thuStatus && tenantOpenHours.thuStart}
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.thuStatus && tenantOpenHours.thuEnd}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Friday
                                        </td>
                                        <td>
                                            {
                                                tenantOpenHours && tenantOpenHours.friStatus ? 
                                                <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                            }
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.friStatus && tenantOpenHours.friStart}
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.friStatus && tenantOpenHours.friEnd}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Saturday
                                        </td>
                                        <td>
                                            {
                                                tenantOpenHours && tenantOpenHours.satStatus ? 
                                                <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                            }
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.satStatus && tenantOpenHours.satStart}
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.satStatus && tenantOpenHours.satEnd}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Sunday
                                        </td>
                                        <td>
                                            {
                                                tenantOpenHours && tenantOpenHours.sunStatus ? 
                                                <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                            }
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.sunStatus && tenantOpenHours.sunStart}
                                        </td>
                                        <td>
                                            {tenantOpenHours && tenantOpenHours.sunStatus && tenantOpenHours.sunEnd}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    {/* <div className="container" style={{ marginBottom:50, marginTop:'50px',textAlign:'center'}}>   
                        <div className="row" style={{fontWeight: 700, fontSize:'20px', marginBottom:'20px'}}>
                            <div className="col-sm">
                                Day 
                            </div>
                            <div className="col-sm">
                                Status
                            </div>
                            <div className="col-sm">
                                Start Time
                            </div>
                            <div className="col-sm">
                                End Time
                            </div>
                        </div>
                        <div className="row openingHours">
                            <div className="col-sm">
                                Monday 
                            </div>
                            <div className="col-sm">
                                {
                                    tenantOpenHours && tenantOpenHours.monStatus ? 
                                    <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                }
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.monStatus && tenantOpenHours.monStart}
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.monStatus && tenantOpenHours.monEnd}
                            </div>
                        </div>
                        <div className="row openingHours">
                            <div className="col-sm">
                                Tuesday 
                            </div>
                            <div className="col-sm">
                                {
                                    tenantOpenHours && tenantOpenHours.tueStatus ? 
                                    <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                }
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.tueStatus && tenantOpenHours.tueStart}
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.tueStatus && tenantOpenHours.tueEnd}
                            </div>
                        </div>
                        <div className="row openingHours">
                            <div className="col-sm">
                                Wednesday 
                            </div>
                            <div className="col-sm">
                                {
                                    tenantOpenHours && tenantOpenHours.wedStatus ? 
                                    <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                }
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.wedStatus && tenantOpenHours.wedStart}
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.wedStatus && tenantOpenHours.wedEnd}
                            </div>
                        </div>
                        <div className="row openingHours">
                            <div className="col-sm">
                                Thursday 
                            </div>
                            <div className="col-sm">
                                {
                                    tenantOpenHours && tenantOpenHours.thuStatus ? 
                                    <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                }
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.thuStatus && tenantOpenHours.thuStart}
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.thuStatus && tenantOpenHours.thuEnd}
                            </div>
                        </div>
                        <div className="row openingHours">
                            <div className="col-sm">
                                Friday 
                            </div>
                            <div className="col-sm">
                                {
                                    tenantOpenHours && tenantOpenHours.friStatus ? 
                                    <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                }
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.friStatus && tenantOpenHours.friStart}
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.friStatus && tenantOpenHours.friEnd}
                            </div>
                        </div>
                        <div className="row openingHours">
                            <div className="col-sm">
                                Saturday 
                            </div>
                            <div className="col-sm">
                                {
                                    tenantOpenHours && tenantOpenHours.satStatus ? 
                                    <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                }
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.satStatus && tenantOpenHours.satStart}
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.satStatus && tenantOpenHours.satEnd}
                            </div>
                        </div>
                        <div className="row openingHours">
                            <div className="col-sm">
                                Sunday 
                            </div>
                            <div className="col-sm">
                                {
                                    tenantOpenHours && tenantOpenHours.sunStatus ? 
                                    <span className='shop-open'> Open </span>: <span className='shop-closed'> Closed </span>
                                }
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.sunStatus && tenantOpenHours.sunStart}
                            </div>
                            <div className="col-sm">
                                {tenantOpenHours && tenantOpenHours.sunStatus && tenantOpenHours.sunEnd}
                            </div>
                        </div>
                    </div> */}
                    
                    <div className="title-area">
                            <h2>OUR LOCATION</h2>
                        </div>
                    <div className="container" style={{ marginBottom:50, marginTop:'50px'}}>   
                        {selectedTenant.billLatitude !== 0 &&
                            selectedTenant.billLongitude !== 0 &&
                                <MapContainer
                                    lat={selectedTenant.billLatitude}
                                    lng={selectedTenant.billLongitude}
                                    businessName={selectedTenant.businessName}
                                />}
                    </div>
                    {/* <div className="card-list">
                        {filteredTenantList && filteredTenantList.length !==0 && filteredTenantList.map(tenants => (
                            <div className="col-sm">
                                <Card
                                    key={tenants.id}
                                    tenants={tenants}
                                    handleTenantDetails={this.handleTenantDetails}
                                />
                            </div>
                        ))}
                    </div> */}

                    {isModalOpen ? 
                        <Modal 
                            isModalOpen={isModalOpen}
                            confirmDetails={this.confirmDetails}
                            handleModalClose={this.handleModalClose}
                            isGetReffererDetails={isGetReffererDetails}
                            isConfirmDetails={isConfirmDetails}
                            handleModalReturn={this.handleModalReturn}
                            isSubmitButtonShow={isSubmitButtonShow}
                            submitReferrerDetails={this.submitReferrerDetails}
                            businessName={selectedTenant.businessName}
                            isError={isError}
                            isSubmitting={isSubmitting}
                            tenants = {selectedTenant}
                            // isErrorDate={isErrorDate}
                        /> : '' 
                    }

                </div>}
            </div>
        )
    }
}

TenantDetails.propTypes = {
    selectedTenantId: PropTypes.any,
    loading: PropTypes.bool,
    selectedTenant: PropTypes.any,
    tenantList:PropTypes.any,
    isError: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    searchText: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    tenantList: selectors.makeSelectTenantList(),
    selectedTenantId: selectors.makeSelectedTenantId(),
    loading: selectors.makeTenantListLoading(),
    selectedTenant: selectors.makeSelectedTenant(),
    isError: selectors.makeAddNewBookingError(),
    isSubmitting: selectors.makeAddNewBookingLoading(),
    searchText: selectors.makeSaveSearchText(),
    tenantServices: selectors.makeSelectTenantServicesSuccess(),
    tenantAreas: selectors.makeSelectTenantServiceAreasSuccess(),
    tenantServicesLoading: selectors.makeSelectTenantServicesLoading(),
    tenantAreasLoading: selectors.makeSelectTenantServiceAreasLoading(),
    tenantOpenHours: selectors.makeSelectTenantOpenHoursSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(TenantDetails));
