import { 
  SAVE_SEARCH_TEXT_DATA,
  LOAD_SELECTED_TENANT_REQUEST,
  LOAD_SELECTED_TENANT_SUCCESS,
  LOAD_TENANT_LIST_LOADING,
    LOAD_TENANT_LIST_REQUEST,
    LOAD_TENANT_LIST_SUCCESS,
    SAVE_SELECTED_TENANT_DATA,
    SEND_ADD_NEW_BOOKING_ERROR,
    SEND_ADD_NEW_BOOKING_LOADING,
    SEND_ADD_NEW_BOOKING_REQUEST,
    LOAD_TENANT_SERVICE_AREA_REQUEST,
    LOAD_TENANT_SERVICE_AREA_SUCCESS,
    LOAD_TENANT_SERVICE_REQUEST,
    LOAD_TENANT_SERVICE_SUCCESS,
    LOAD_TENANT_SERVICE_AREA_LOADING,
    LOAD_TENANT_SERVICE_LOADING,
    LOAD_TENANT_OPEN_HOURS_REQUEST,
    LOAD_TENANT_OPEN_HOURS_SUCCESS
  } from './Constant';
  
  export function loadTenantListRequest(data) {
    return {
      type: LOAD_TENANT_LIST_REQUEST,
      payload: data,
    }
  };

  export function loadTenantListLoading(payload) {
    return{
      type: LOAD_TENANT_LIST_LOADING,
      payload,
    }
  };

  export function loadTenantListData(data){
    return {
      type: LOAD_TENANT_LIST_SUCCESS,
      payload: data,
    }
  };

  export function saveSelectedTenantData(data){
    return {
      type: SAVE_SELECTED_TENANT_DATA,
      payload: data,
    }
  }

  export function loadSelectedTenantRequest(data){
    return{
      type: LOAD_SELECTED_TENANT_REQUEST,
      payload: data,
    }
  }

  export function loadSelectedTenantData(data){
    return{
      type:LOAD_SELECTED_TENANT_SUCCESS,
      payload: data,
    }
  }

  export function saveSearchTextData(data) {
    return {
      type: SAVE_SEARCH_TEXT_DATA,
      payload: data,
    }
  };

  export function sendAddNewBookingRequest (data) {
    return {
      type: SEND_ADD_NEW_BOOKING_REQUEST,
      payload: data,
    }
  }

  export function sendAddNewBookingError (data) {
    return {
      type: SEND_ADD_NEW_BOOKING_ERROR,
      payload: data,
    }
  }

  export function sendAddNewBookingLoading (data) {
    return {
      type: SEND_ADD_NEW_BOOKING_LOADING,
      payload: data,
    }
  }


      
  export function loadTenantServicesAreaRequest(data) {
    return {
      type: LOAD_TENANT_SERVICE_AREA_REQUEST,
      payload: data,
    }
  };

  export function loadTenantServicesAreaLoading(data) {
    return {
      type: LOAD_TENANT_SERVICE_AREA_LOADING,
      payload: data,
    }
  };

  export function loadTenantServicesAreaSuccess(data) {
      return {
        type: LOAD_TENANT_SERVICE_AREA_SUCCESS,
        payload: data,
      }
    };
    
  export function loadTenantServicesRequest(data) {
      return {
        type: LOAD_TENANT_SERVICE_REQUEST,
        payload: data,
      }
    };

  export function loadTenantServicesLoading(data) {
      return {
        type: LOAD_TENANT_SERVICE_LOADING,
        payload: data,
      }
    };

  export function loadTenantServicesSuccess(data) {
      return {
        type: LOAD_TENANT_SERVICE_SUCCESS,
        payload: data,
      }
    };

  export function loadTenantOpenHoursRequest(data) {
    return {
      type: LOAD_TENANT_OPEN_HOURS_REQUEST,
      payload: data,
    }
  };
    
  export function loadTenantOpenHoursSuccess(data) {
    return {
      type: LOAD_TENANT_OPEN_HOURS_SUCCESS,
      payload: data,
    }
  };
  