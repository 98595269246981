import React from 'react';
import BootstrapButton from 'react-bootstrap/cjs/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

const Button = ({
    variant,
    text,
    onClick,
    type,
    ...props
}) => (
    <div style={{padding:'5px', margin:'5px', width:"150px"}}>
        <BootstrapButton  style = {{width:'100%'}}
            variant = {variant}
            onClick = {onClick}
            type = {type}
            {...props}
        >
            {text}
        </BootstrapButton>
    </div>
);

Button.propTypes = {
    variant: PropTypes.any,
    text: PropTypes.string,
    onClick: PropTypes.any,
    type: PropTypes.any,
}

export default Button;