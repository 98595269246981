import React , { Component }from 'react';
import { Link } from 'react-router-dom';
import "./Layout.css";
import VemasLogo from '../../images/find_a_mechanic.png';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import Button from '../form/button/Button'
// import {
//     Navbar,
//     Nav,
//     Form
// } from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';
function openInNewTab  (url){
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

class NavBar extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        isModalOpen: false,
    };
  }

handleSubscribe = () => {
  this.setState({
    isModalOpen: true
  })
}

handleSubscribeClose = () => {
  this.setState({
    isModalOpen: false
  })
}

render() {
  return (
    <div>
      
      <div className="header">
           <div className="nav_left" onClick={()=> window.location.href='/'}>
              <img alt="vemaslogo" id="vemaslogo" style={ { width: '12%' } } src={VemasLogo}/>
           </div>
           <div className="nav_right">
             <Link className="nav_text" onClick={()=> window.location.href='/'}>Home</Link>
             <Link className="nav_text" onClick={() => this.handleSubscribe()}>Registration</Link>
             <Link className="nav_text">ContactUs</Link>
             <Link onClick={()=> {openInNewTab('https://app.prd.vemas.com.au/')}} className="nav_text" >Login</Link>
           </div>
    </div>
    {this.state.isModalOpen ? 
      <BootstrapModal 
        show={this.state.isModalOpen}
        onHide={this.handleSubscribeClose}
        animation={false}
        size="md"
        // dialogClassName="modal_width"
        centered
      >
        <div>
          <BootstrapModal.Header>
            <BootstrapModal.Title style={{fontSize:'1.1rem'}}></BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body style={{height:'75vh'}}>
            <iframe title="Subscription" src={`https://subscription.dev.vemas.com.au/`} height="100%" width="100%" frameBorder="0" ></iframe>
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <div>
              <Button
                variant="primary" 
                text ='Close'
                type="submit"
                onClick={this.handleSubscribeClose}
                size="sm"
              />
            </div>
          </BootstrapModal.Footer>
        </div>
      </BootstrapModal>
        : '' }
    </div>
  );
}
}

export default NavBar;
