import React from 'react';
import '../../App.css';
import { HashRouter, Switch, Route } from 'react-router-dom';
import AppBar from '../../components/layout/AppBar';
import Dashboard from '../../components/pages/Dashboard';
import TenantDetails from '../../components/pages/TenantDetails';
// import Booking from './components/pages/Booking';
import Footer from '../../components/layout/Footer';

function Pages() {
  return (
    <HashRouter>
    <AppBar fixed="top"/>
      <div className="App">
          <Switch>
            <Route exact path='/' component={ Dashboard } />
            {/* <Route path='/:searchText' component={ Dashboard} /> */}
            <Route path='/tenantDetails/:id' component={ TenantDetails }/>
            {/* <Route path='/booking' component={ Booking } /> */}
        </Switch>
      </div>
    <Footer/>
    </HashRouter>
  );
}

export default Pages;
