import { createSelector } from 'reselect';

const selectTenantDomain = state => state.tenant;

const makeSelectTenantList =() =>
  createSelector(
    selectTenantDomain,
    tenantState => 
      tenantState ? tenantState.get('tenantList') : []
  );

const makeTenantListLoading = () =>
  createSelector(
    selectTenantDomain,
    tenantState =>
    tenantState ? tenantState.get('loading') : false
  );

const makeSelectedTenantId =() =>
  createSelector(
    selectTenantDomain,
    tenantState => 
      tenantState ? tenantState.get('selectedTenantId') : {}
  );

const makeSelectedTenant =() =>
  createSelector(
    selectTenantDomain,
    tenantState => 
      tenantState ? tenantState.get('selectedTenant') : {}
  );

const makeAddNewBookingLoading = () =>
  createSelector(
    selectTenantDomain,
    tenantState =>
    tenantState ? tenantState.get('isSubmitting') : false
  );
  
const makeAddNewBookingError = () =>
  createSelector(
    selectTenantDomain,
    tenantState =>
    tenantState ? tenantState.get('isError') : null
  );
  
  const makeSaveSearchText = () =>
    createSelector(
      selectTenantDomain,
      tenantState =>
      tenantState ? tenantState.get('searchText') : {}
    );

  
  const makeSelectTenantServicesSuccess = () =>
    createSelector(
      selectTenantDomain,
      tenantDetailsState => 
        tenantDetailsState ? tenantDetailsState.get('tenantServices') : []
    );
  
  const makeSelectTenantServiceAreasSuccess = () =>
    createSelector(
      selectTenantDomain,
      tenantDetailsState => 
        tenantDetailsState ? tenantDetailsState.get('tenantAreas') : []
  );
  
  
  const makeSelectTenantServicesLoading = () =>
    createSelector(
      selectTenantDomain,
      tenantDetailsState => 
        tenantDetailsState ? tenantDetailsState.get('tenantServicesLoading') : false
  );
  
  const makeSelectTenantServiceAreasLoading = () =>
    createSelector(
      selectTenantDomain,
      tenantDetailsState => 
        tenantDetailsState ? tenantDetailsState.get('tenantAreasLoading') : false
  );

  const makeSelectTenantOpenHoursSuccess = () =>
    createSelector(
      selectTenantDomain,
      tenantDetailsState => 
        tenantDetailsState ? tenantDetailsState.get('tenantOpenHours') : {}
    );

export {
    makeSelectTenantList,
    makeSelectedTenantId,
    makeTenantListLoading,
    makeSelectedTenant,
    makeAddNewBookingLoading,
    makeAddNewBookingError,
    makeSaveSearchText,
    makeSelectTenantServicesSuccess,
    makeSelectTenantServiceAreasSuccess,
    makeSelectTenantServicesLoading,
    makeSelectTenantServiceAreasLoading,
    makeSelectTenantOpenHoursSuccess
}
