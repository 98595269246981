
import React, { Component } from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import Alert from 'react-bootstrap/cjs/Alert'
import PropTypes from 'prop-types';
import Button from '../form/button/Button'
import Form from 'react-bootstrap/cjs/Form'
import TextField from '../form/textField/TextField'
import "./Layout.css";
import DatePicker from '../form/datePicker/datePicker';
// import Loader from './Loader';
// import ConfirmModal from '../layout/ConfirmModal'

export class Modal extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            vehicalRegNo: '',
            name: '',
            email: '',
            phoneNumber: '',
            date: '',
            massage: '',
            isDateValidate: false
        };
        // this.confirmDetails = this.confirmDetails.bind(this);
        // this.vehicalRegNoChange = this.vehicalRegNoChange.bind(this);
      };

    // confirmDetails=()=>{
    //     // console.log(this.state)
    //     this.setState ({isConfirmModalOpen:true})
    // }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeDate  = (date) => {
        // console.log(date)
        this.setState({date:date})
    }
  
    render() {
        const {
            vehicalRegNo,
            name,
            email,
            phoneNumber,
            date,
            isDateValidate
        } = this.state;

        const {
            isModalOpen,
            isGetReffererDetails,
            handleModalClose,
            confirmDetails,
            isConfirmDetails,
            handleModalReturn,
            isSubmitButtonShow,
            submitReferrerDetails,
            businessName,
            isError,
            tenants,
            // isErrorDate
            // isSubmitting,
        } = this.props;

        // const { 
        //     isConfirmModalOpen
        // } = this.state;

        return (
            <div>
                <BootstrapModal 
                    show ={isModalOpen} 
                    onHide={handleModalClose}
                    size="md"
                    // dialogClassName="dialog"
                    // style={{width:'90vw'}}
                >
                    <BootstrapModal.Header closeButton>
                        <BootstrapModal.Title style={{textAlign:'center'}}>{businessName}</BootstrapModal.Title>
                    </BootstrapModal.Header>
                
                    { isGetReffererDetails ?
                        <div>
                            <BootstrapModal.Body style={{maxHeight: 'calc(100vh - 180px)', overflowY: 'auto'}}>
                                <Form className="details-form"> 
                                    <TextField
                                        controlId="vehicalRegNo"
                                        text="Vehicle Registration No"
                                        placeholder="Enter Vehicle Registration No"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.vehicalRegNo}
                                    />
                                    <TextField
                                        controlId="name"
                                        text="Name"
                                        placeholder="Enter Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.name}
                                    />
                                    <TextField
                                        controlId="email"
                                        text="Email address"
                                        placeholder="Enter Email"
                                        type="email"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.email}
                                    />
                                    <TextField
                                        controlId="phoneNumber"
                                        text="Phone Number"
                                        placeholder="Enter Phone Number"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.phoneNumber}
                                    />
                                    {/* <TextField
                                        controlId="date"
                                        text={isDateValidate ? <div>Select Date <small style={{color:'red'}}> * Enter a valid date</small></div>: "Select Date"}
                                        type="date"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.date}
                                    /> */}
                                    <DatePicker
                                        controlId="date"
                                        text={isDateValidate ? <div>Select Date <small style={{color:'red'}}> * Enter a valid date</small></div>: "Select Date"}
                                        onChange={this.handleChangeDate}
                                        value={this.state.date}
                                        
                                    />
                                    <TextField
                                        controlId="massage"
                                        text="Message to mechanic"
                                        placeholder="Message to mechanic"
                                        type="text"
                                        as="textarea" 
                                        rows={3}
                                        onChange={this.handleChange}
                                        defaultValue={this.state.massage}
                                    />
                                </Form>
                            </BootstrapModal.Body>
                        
                            <BootstrapModal.Footer style={{justifyContent:'center'}}>
                                <Button 
                                    className="button"
                                    type="submit" 
                                    onClick={confirmDetails}
                                    variant="primary" 
                                    text ={tenants && tenants.expireStatus ? 'Inquiry' :'Book Online'}
                                    disabled={
                                        (name === ''||
                                        date === '' ||
                                        date === null ||
                                        vehicalRegNo === '' ||
                                        email === ''||
                                        phoneNumber === '') && 
                                        (!isDateValidate)
                                    }
                                     
                                />
                                <Button 
                                    variant="secondary"
                                    text="Close"
                                    onClick={handleModalClose}
                                />
                            </BootstrapModal.Footer>
                        </div> : ''
                    }
                    {isConfirmDetails ?
                        <div>
                            <BootstrapModal.Body>
                                <div className="confirm">
                                    <div className="confirm-title">
                                        <p>Vehicle RegNo</p>
                                        <p>Name</p>
                                        <p>Email</p>
                                        <p>Phone Number</p>
                                        <p>Date</p>
                                    </div>
                                    <div className="confirm-details">
                                        <p>: {this.state.vehicalRegNo} </p>
                                        <p>: {this.state.name}</p>
                                        <p>: {this.state.email}</p>
                                        <p>: {this.state.phoneNumber}</p>
                                        {/* <p>: {this.state.date}</p> */}
                                        <p>: {this.state.date.getFullYear(this.state.date)+'-'+(this.state.date.getMonth(this.state.date)+1)+'-'+(this.state.date.getDate(this.state.date))}</p>
                                    </div>
                                </div>
                            </BootstrapModal.Body>
                        
                            {isSubmitButtonShow ? 
                                <div>
                                    <BootstrapModal.Footer style={{justifyContent:'center'}}>
                                        <Button 
                                            variant="primary" 
                                            text="Submit"
                                            onClick={()=>submitReferrerDetails(this.state)}
                                        />
                                        <Button 
                                            variant="secondary"
                                            text="Return"
                                            onClick={handleModalReturn}
                                            />
                                    </BootstrapModal.Footer>
                                </div> : 
                                <div>
                                    <BootstrapModal.Footer style={{justifyContent:'center'}}>
                                    {isError ? 
                                        <Alert variant="danger" className="modal-alert">
                                            Error ! Booking request was not submitted.
                                        </Alert> :
                                        <Alert variant="success" className="modal-alert">
                                            Success ! Booking request was submitted.
                                        </Alert>}
                                        {/* {isSubmitting && <Loader/>} */}
                                    </BootstrapModal.Footer>
                                </div>
                            }
                        </div> : ''
                    }
            </BootstrapModal>

            {/* {isConfirmModalOpen ? 
                <ConfirmModal
                    isModalOpen={isConfirmModalOpen}
                /> : ''} */}
        </div>
        )
    }
}

Modal.propTypes = {
    isModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.any,
    confirmDetails: PropTypes.any,
    isGetReffererDetails: PropTypes.any,
    isConfirmDetails: PropTypes.any,
    handleModalReturn: PropTypes.any,
    isSubmitButtonShow: PropTypes.any,
    submitReferrerDetails: PropTypes.any,
    isError: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    // isErrorDate: PropTypes.bool
}

export default Modal;