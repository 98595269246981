export function formatNumber(number) {
    // Remove any non-digit characters from the number
    // const cleaned = ('' + number).replace(/\D/g, '');

    // Add a space after every third character
    let formatted = '';
    for (let i = 0; i < number.length; i++) {
        if (i > 0 && i % 3 === 0) {
            formatted += ' ';
        }
        formatted += number.charAt(i);
    }

    return formatted;
}