import { takeLatest, put, call } from 'redux-saga/effects';
import { 
  // LOAD_SEARCH_TENANT_LIST_REQUEST, 
  LOAD_SELECTED_TENANT_REQUEST, 
  LOAD_TENANT_LIST_REQUEST, 
  LOAD_TENANT_OPEN_HOURS_REQUEST, 
  LOAD_TENANT_SERVICE_AREA_REQUEST, 
  LOAD_TENANT_SERVICE_REQUEST, 
  SEND_ADD_NEW_BOOKING_REQUEST
} from '../store/Constant';
import {
  getTenantList, 
  getSelectedTenant,
  // getSearchTenantList,
  addNewBooking,
  getTenantServices,
  getTenantServiceArea,
  getTenantOpeningHoursRequest
} from './service';
import * as actions from '../store/Action';

export function* getTenantsListDetails(data) {
    // console.log('getTenantsListDetails')
    try{
        yield put(actions.loadTenantListLoading(true));
        const response = yield call(getTenantList, data.payload);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200){
          const dataList = response.data.result.result.filter(data => (data.online === "1"))
          yield put(actions.loadTenantListData(dataList))
          // console.log(response.data.result.result)
      }
      // else{
      //     console.log(response.data)
      // }
    yield put(actions.loadTenantListLoading(false));
  
    }catch(error){
        // console.log(error)
    yield put(actions.loadTenantListLoading(false));
    }
};

export function* getSelectedTenantDetails(data) {
  // console.log('getSelectedTenantDetails'+ data.payload)
  try{
      yield put(actions.loadTenantListLoading(true));
      const response = yield call(getSelectedTenant, data.payload);
    if(response && response.status && response.status === 200){
        yield put(actions.loadSelectedTenantData(response.data))
        // console.log(response.data)
        // window.location.href=`./tenantDetails/${data.payload}`
    }
    // else{
    //     console.log(response.status)
    // }
  yield put(actions.loadTenantListLoading(false));

  }catch(error){
      // console.log(error)
  yield put(actions.loadTenantListLoading(false));
  }
};

// export function* getSearchTenantsListDetails(data) {
//   console.log('getSearchTenantsListDetails')
//   try{
//       yield put(actions.loadTenantListLoading(true));
//       const response = yield call(getSearchTenantList, data.payload);
//     if(response && response.data && response.data.statusCode && response.data.statusCode === 200){
//         yield put(actions.loadTenantListData(response.data.result.result))
//         console.log(response.data.result.result)
//     }else{
//         console.log(response.data.statusCode)
//     }
//   yield put(actions.loadTenantListLoading(false));

//   }catch(error){
//       console.log(error)
//   yield put(actions.loadTenantListLoading(false));
//   }
// };

export function* sendAddNewBookingRequest(data){
  // console.log('sendAddNewBookingRequest')
  try{
    yield put(actions.sendAddNewBookingLoading(true));
    const response = yield call(addNewBooking,data.payload);
    if(response &&response.data && response.data.statusCode && response.data.statusCode === 201 ){
      // console.log(response)
      // global.showSuccessBox('You have Successfully Update Your Profile');
      // yield put(actions.loadUserProfileRequest());
      yield put(actions.sendAddNewBookingError(false));
    }else{
      yield put(actions.sendAddNewBookingError(true));
      // console.log(response)
      // global.showErrorBox('','Not Successfully Update Your Profile','');
    }  
    yield put(actions.sendAddNewBookingLoading(false));
  }catch(error){
    // yield put(actions.sendUserProfileUpdateError());
    // yield put(actions.sendUserProfileUpdateLoading(false));
    // console.log(error)
    yield put(actions.sendAddNewBookingError(true));
    yield put(actions.sendAddNewBookingLoading(false));
  }
}

export function* loadTenantServicesRequest(data){
  try{
    const response = yield call(getTenantServices, data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data)
      const tenant = response.data.result.result.$values
      yield put(actions.loadTenantServicesSuccess(tenant))
    }
    yield put(actions.loadTenantServicesLoading(false))
  }catch(error){
    // console.log(error)
    yield put(actions.loadTenantServicesLoading(false))
  }
}



export function* loadTenantServicesAreaRequest(data){
  try{
    const response = yield call(getTenantServiceArea, data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data)
      const tenant = response.data.result.result.$values
      yield put(actions.loadTenantServicesAreaSuccess(tenant))
    }
    yield put(actions.loadTenantServicesAreaLoading(false))
  }catch(error){
    // console.log(error)
    yield put(actions.loadTenantServicesAreaLoading(false))
  }
}

export function* loadTenantOpenHoursRequest(data){
  try{
    const response = yield call(getTenantOpeningHoursRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      const tenant = response.data
      yield put(actions.loadTenantOpenHoursSuccess(tenant))
    }
  }catch(error){
    yield put(actions.loadTenantListLoading(false));
}
}


  export default function* rootSaga(){
    yield takeLatest(LOAD_TENANT_LIST_REQUEST,getTenantsListDetails);
    yield takeLatest(LOAD_SELECTED_TENANT_REQUEST,getSelectedTenantDetails);
    // yield takeLatest(LOAD_SEARCH_TENANT_LIST_REQUEST,getSearchTenantsListDetails);
    yield takeLatest(SEND_ADD_NEW_BOOKING_REQUEST,sendAddNewBookingRequest);
    yield takeLatest(LOAD_TENANT_SERVICE_AREA_REQUEST, loadTenantServicesAreaRequest);
    yield takeLatest(LOAD_TENANT_SERVICE_REQUEST, loadTenantServicesRequest);
    yield takeLatest(LOAD_TENANT_OPEN_HOURS_REQUEST, loadTenantOpenHoursRequest);
  }