import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import "./Layout.css";
import {
    Form,
    // Row,
    // Col
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class SearchField extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            searchText:'',
        };
    
      };

    handleSearchText=(e)=>{
        this.setState ({searchText:e.target.value})
    }

    render(){

        const { handleSearch } = this.props;

        return(
            <div className="container">
                <Form className="form">
                    <Form.Group controlId="searchField" >
                        <div className="searchField" >
                            <div className="col-sm-11 col-10">
                                <Form.Control 
                                    size="md" 
                                    type="search" 
                                    placeholder="Enter Name or Suburb"
                                    onChange = {this.handleSearchText}
                                    onKeyPress = {(e) => {e.key === "Enter" && handleSearch(this.state.searchText)}}
                                />
                            </div>
                            <div className="col-sm-1 col-2 search-icon" onClick={()=>handleSearch(this.state.searchText)}>
                                <SearchIcon/>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}

SearchField.propTypes ={
    handleSearch: PropTypes.bool,
}

export default SearchField;