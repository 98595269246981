const PATH = 'app/Tenant';

export const LOAD_TENANT_LIST_REQUEST = `${PATH}LOAD_TENANT_LIST_REQUEST`;
export const LOAD_TENANT_LIST_LOADING = `${PATH}LOAD_TENANT_LIST_LOADING`;
export const LOAD_TENANT_LIST_SUCCESS = `${PATH}LOAD_TENANT_LIST_SUCCESS`;
export const LOAD_SELECTED_TENANT_REQUEST = `${PATH}LOAD_SELECTED_TENANT_REQUEST`;
export const LOAD_SELECTED_TENANT_SUCCESS = `${PATH}LOAD_SELECTED_TENANT_SUCCESS`;
export const SAVE_SELECTED_TENANT_DATA = `${PATH}SAVE_SELECTED_TENANT_DATA`;
export const SAVE_SEARCH_TEXT_DATA = `${PATH}SAVE_SEARCH_TEXT_DATA`;
export const SEND_ADD_NEW_BOOKING_REQUEST = `${PATH}SEND_ADD_NEW_BOOKING_REQUEST`;
export const SEND_ADD_NEW_BOOKING_ERROR = `${PATH}SEND_ADD_NEW_BOOKING_ERROR`;
export const SEND_ADD_NEW_BOOKING_LOADING = `${PATH}SEND_ADD_NEW_BOOKING_LOADING`;
export const LOAD_TENANT_SERVICE_REQUEST = `${PATH}LOAD_TENANT_SERVICE_REQUEST`;
export const LOAD_TENANT_SERVICE_SUCCESS = `${PATH}LOAD_TENANT_SERVICE_SUCCESS`;
export const LOAD_TENANT_SERVICE_LOADING = `${PATH}LOAD_TENANT_SERVICE_LOADING`;
export const LOAD_TENANT_SERVICE_AREA_REQUEST = `${PATH}LOAD_TENANT_SERVICE_AREA_REQUEST`;
export const LOAD_TENANT_SERVICE_AREA_SUCCESS = `${PATH}LOAD_TENANT_SERVICE_AREA_SUCCESS`;
export const LOAD_TENANT_SERVICE_AREA_LOADING = `${PATH}LOAD_TENANT_SERVICE_AREA_LOADING`;
export const LOAD_TENANT_OPEN_HOURS_REQUEST = `${PATH}LOAD_TENANT_OPEN_HOURS_REQUEST`;
export const LOAD_TENANT_OPEN_HOURS_SUCCESS = `${PATH}LOAD_TENANT_OPEN_HOURS_SUCCESS`;