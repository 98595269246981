import React from 'react';
import './App.css';
import { HashRouter, Switch, Route } from 'react-router-dom';
import OnlineBooking from './components/pages/OnlineBooking/OnlineBooking';
import Pages from './components/pages/pages';

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path='/OnlineBooking/:channel/:id' component={ OnlineBooking }/>
        <Pages/>
    </Switch>
    </HashRouter>
  );
}

export default App;
