import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as selectors from '../../store/Selector';
import * as actions from '../../store/Action';
import SearchField from '../layout/SearchField';
import './page.css';
import Background from '../../images/banner6.jpg'
import Card from '../layout/Cards';
import Loader from '../layout/Loader';
import Axios from 'axios';

class DashBoard extends Component {
    
    constructor(props) {
    super(props);

    this.state = { 
        isShowTenantDetails:false,
        scrolling: false,
        pageSize:8,
        pageNumber:1,
        searchText:'',
        fullTenantList: [],
        initial: true,
        hasMore: true,
        loading: true
    };

  };

  componentDidMount(){
    const parameters ={
        pageSize: this.state.pageSize,
        PageNumber: this.state.pageNumber,
        searchText: this.state.searchText
    }
        this.getTenant(parameters)
  }

  getTenant = (parameters) => {
    var pageSize = parameters.pageSize;
    if(parameters.searchText ===''){
        pageSize = 9999
    }
    setTimeout(() => {
        this.setState({ loading: true });
        Axios
    .get(`${process.env.REACT_APP_API_URL}Tenants?Search=${parameters.searchText}&pageSize=${pageSize}&PageNumber=${parameters.PageNumber}`,{
        headers: {
            'Content-Type': 'application/json',
            'authorization' : process.env.REACT_APP_API_KEY
        }
    })
    .then(res => {
                const dataList = res.data.result.result.filter(data => (data.online === "1"))
                this.setState({ fullTenantList: dataList});
                this.setState({ loading: false });
            });
    }, 300)
    
  }

    loadMoreTenants = () => {
        const { fullTenantList } =this.state;
            const newPageNumber = this.state.pageNumber + 1
            const parameters ={
                pageSize: this.state.pageSize,
                PageNumber: newPageNumber,
                searchText: this.state.searchText
            }

            Axios
            .get(`${process.env.REACT_APP_API_URL}Tenants?Search=${parameters.searchText}&pageSize=${parameters.pageSize}&PageNumber=${newPageNumber}`,{
                headers: {
                    'Content-Type': 'application/json',
                    'authorization' : process.env.REACT_APP_API_KEY
                }
            })
            .then(res => {
                const dataList = res.data.result.result.filter(data => (data.online === "1"))
                this.setState({ fullTenantList: fullTenantList.concat(dataList), pageNumber:newPageNumber});
            });
    }

    handleSearch = (searchText) =>{
        
        this.setState({searchText:searchText,pageNumber:1})
        const parameters = {
            pageSize: 6,
            PageNumber: 1,
            searchText: searchText
        }
        this.props.dispatch(actions.saveSearchTextData(searchText))
        // // this.props.dispatch(actions.loadTenantListRequest(parameters));
        this.getTenant(parameters)
    }

    handleTenantDetails = (tenant) => {
        this.props.history.push("/tenantDetails/" + tenant.id)
        this.props.dispatch(actions.saveSelectedTenantData(tenant));
        window.localStorage.setItem('postalCode', tenant.billPostalCode)
    }

    render(){
        
        const { 
            fullTenantList,
            hasMore,
            loading
        } =this.state;

        return(
            <div>
                {loading && <Loader/>}
                {<div style={{backgroundImage: "url(" + Background + ")", backgroundRepeat:"no-repeat", backgroundSize:"100%"}}>
                        <div >
                            <div className="search">
                                <SearchField
                                    handleSearch={this.handleSearch}
                                />
                            </div> 
                                <InfiniteScroll
                                    dataLength={fullTenantList && fullTenantList.length}
                                    next={this.loadMoreTenants}
                                    hasMore={hasMore}
                                >
                                    <div className="card-list">
                                            {fullTenantList && fullTenantList.map(tenants => (
                                            <div className="col-sm">
                                                <Card
                                                    key={tenants.id}
                                                    tenants={tenants}
                                                    handleTenantDetails={this.handleTenantDetails}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </InfiniteScroll>
                        </div>
                </div>}
            </div>
        )
    }
}

DashBoard.propTypes = {
    tenantList: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    tenantList: selectors.makeSelectTenantList(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(DashBoard));