import React from 'react';
import DatePicker from 'react-date-picker';
import BootstrapFormLabel from 'react-bootstrap/cjs/FormLabel';
import BootstrapFormGroup from 'react-bootstrap/cjs/FormGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './datePicker.css'

const datePicker = ({
    controlId,
    text,
    // type,
    placeholder,
    // as,
    // row,
    onChange,
    value,
    // ...props
}) => (
    <div style={{fontSize:'14px'}}>
        {/* <BootstrapFormGroup controlId={controlId}>
            <BootstrapFormLabel>
                {text}
            </BootstrapFormLabel>
            <BootstrapFormControl 
                {...props}
                type={type}
                placeholder={placeholder}
                as={as}
                row={row}
                onChange={onChange}
                size="sm"
            />
        </BootstrapFormGroup> */}

        <BootstrapFormGroup controlId={controlId}>
            <div><BootstrapFormLabel>{text}</BootstrapFormLabel></div>
                <DatePicker 
                className="datePicker"
                    onChange={onChange} 
                    placeholder={placeholder} 
                    value={value} 
                    minDate={new Date()}
                    format="dd-MM-y"/>
        </BootstrapFormGroup>
    </div>
);

datePicker.propTypes = {
    controlId: PropTypes.any,
    text: PropTypes.any,
    // type: PropTypes.any,
    placeholder: PropTypes.any,
    // as: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.any,
}

export default datePicker;
