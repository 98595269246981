import React from "react";
import "./Layout.css";

function Loader() {
  return (
    <div className="loader_bg">
        <div className="loader"></div>
    </div>
  );
}

export default Loader;