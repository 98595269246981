import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {GoogleMap, withScriptjs, withGoogleMap,Marker } from 'react-google-maps';

function Map (location){
  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{lat:location.lat, lng:location.lng}}
    >
      <Marker
      title={location.businessName}
      name={location.businessName}
      position={{lat: location.lat, lng: location.lng}} 
      ></Marker>
    </GoogleMap>
  )
}

const WrappedMap = withScriptjs(withGoogleMap((location)=>Map(location)));
const apiKey= "AIzaSyBG9MyEOI_p0kpVlQsU0ozTIjl09mRKC7U"
 
export class MapContainer extends Component {
  
  render() {
    const {lat, lng, businessName} =this.props;
    return (
      <div>
        <div style={{ width: "100%", height: "60vh", marginLeft:'auto' }}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        lat={lat}
        lng={lng}
        businessName={businessName}
      />
    </div>
    


          {/* <Map google={this.props.google} zoom={14}>
 
            <Marker onClick={this.onMarkerClick}
                    name={'Current location'} />

            <InfoWindow onClose={this.onInfoWindowClose}>
                <div>
                <h1>{this.state.selectedPlace.name}</h1>
                </div>
            </InfoWindow>
            </Map> */}
     </div>
    );
  }
}

MapContainer.propTypes = {
  lat: PropTypes.any,
  lng: PropTypes.any,
  businessName: PropTypes.any
}

export default MapContainer
// export default GoogleApiWrapper({
//   apiKey: ("AIzaSyBG9MyEOI_p0kpVlQsU0ozTIjl09mRKC7U")
// })(MapContainer)