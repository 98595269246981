import { fromJS } from 'immutable';
import {
  LOAD_SELECTED_TENANT_SUCCESS,
  LOAD_TENANT_LIST_LOADING,
  LOAD_TENANT_LIST_SUCCESS,
  SAVE_SELECTED_TENANT_DATA,
  SEND_ADD_NEW_BOOKING_LOADING,
  SEND_ADD_NEW_BOOKING_ERROR,
  SAVE_SEARCH_TEXT_DATA,
  LOAD_TENANT_SERVICE_SUCCESS,
  LOAD_TENANT_SERVICE_AREA_SUCCESS,
  LOAD_TENANT_SERVICE_LOADING,
  LOAD_TENANT_SERVICE_AREA_LOADING,
  LOAD_TENANT_OPEN_HOURS_SUCCESS
} from './Constant';

export const initialState = fromJS({
  loading:true,
  tenantList:{},
  selectedTenantId:{},
  selectedTenant:{},
  isError: null,
  isSubmitting: false,
  searchText:'',
  tenantServices:[],
  tenantAreas:[],
  tenantAreasLoading:false,
  tenantServicesLoading:false,
  tenantOpenHours:{}
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TENANT_LIST_LOADING:
      return state.set('loading', action.payload);
    case LOAD_TENANT_LIST_SUCCESS:
      return state.set('tenantList', action.payload);
    case SAVE_SELECTED_TENANT_DATA:
      return state.set('selectedTenantId', action.payload);
    case LOAD_SELECTED_TENANT_SUCCESS:
      return state.set('selectedTenant', action.payload);
    case SEND_ADD_NEW_BOOKING_LOADING:
      return state.set('isSubmitting', action.payload);
    case SEND_ADD_NEW_BOOKING_ERROR:
      return state.set('isError', action.payload);
    case SAVE_SEARCH_TEXT_DATA:
      return state.set('searchText', action.payload);
    case LOAD_TENANT_SERVICE_SUCCESS:
      return state.set('tenantServices', action.payload);
    case LOAD_TENANT_SERVICE_AREA_SUCCESS:
      return state.set('tenantAreas', action.payload);
    case LOAD_TENANT_SERVICE_LOADING:
      return state.set('tenantServicesLoading', action.payload);
    case LOAD_TENANT_SERVICE_AREA_LOADING:
      return state.set('tenantAreasLoading', action.payload);
    case LOAD_TENANT_OPEN_HOURS_SUCCESS:
      return state.set('tenantOpenHours', action.payload)
    default:
      return state;
  }
}

export default reducer;
