import React from "react";
import "./Layout.css";

function Footer() {
  return (
    <div className="main-footer">
      {/* <div style={{margin:'auto'}}> */}
        {/* <div className="row"> */}
          {/* Column1 */}
          <div>
            <span className="footer-text">Copyright C 2020 Hithsa Web Design</span>
          </div>
          {/* Column2 */}
          <div>
            <span className="footer-text">Term & Condition</span>
          </div>
          {/* Column3 */}
          <div>
            <span className="footer-text">Designed by Hithsa Web Design</span>
          </div>
        {/* </div> */}
      {/* </div> */}
    </div>
  );
}

export default Footer;