import React from 'react';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import BootstrapFormLabel from 'react-bootstrap/cjs/FormLabel';
import BootstrapFormGroup from 'react-bootstrap/cjs/FormGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

const TextField = ({
    controlId,
    text,
    type,
    placeholder,
    as,
    row,
    onChange,
    ...props
}) => (
    <div style={{fontSize:'14px'}}>
        <BootstrapFormGroup controlId={controlId}>
            <BootstrapFormLabel>
                {text}
            </BootstrapFormLabel>
            <BootstrapFormControl 
                {...props}
                type={type}
                placeholder={placeholder}
                as={as}
                row={row}
                onChange={onChange}
                size="sm"
            />
        </BootstrapFormGroup>
    </div>
);

TextField.propTypes = {
    controlId: PropTypes.any,
    text: PropTypes.any,
    type: PropTypes.any,
    placeholder: PropTypes.any,
    as: PropTypes.any,
    row: PropTypes.any,
    onChange: PropTypes.any,
}

export default TextField;