import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./Layout.css";
// import Rating from '@material-ui/lab/Rating';
import {
    Card,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import {
//     Facebook, 
//     Web,
//     MailOutlineOutlined,
//     Twitter,
// } from '@material-ui/icons';
import image from '../../images/image1.jpg'
import Button from '../form/button/Button'
import { formatNumber } from '../common';

class Cards extends Component {

    openInNewTab = (url)=>{
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render(){       
        const {tenants,handleTenantDetails} =this.props;
        return(
            <div>
                <Card className="card" border="primary" >
                    <Card.Body>
                    <div className="card-top">
                        <div className="col-sm-3 tenant-logo-card" >
                            <Card.Img src={tenants && tenants.logo ? tenants.logo : image} />
                        </div>
                        <div className="col-sm-9">
                            <h5 className="businessName">{tenants.businessName}</h5>
                            <Card.Text>
                                {/* {tenants.addresses && tenants.addresses.length !==0 &&  */}
                                    <div>
                                        <span>{tenants.billStreetNumber}, {tenants.billStreetName}</span> <br/>
                                        <span>{tenants.billCity}, {tenants.billPostalCode}, {tenants.billRegion}</span>
                                    </div>
                                     {/* } */}
                            </Card.Text>
                            <Card.Text>Phone : <a href={tenants.phoneNumber} style={{color:'#000000'}}>{formatNumber(tenants.phoneNumber)}</a></Card.Text>
                            {/* <small>Email : {tenants.email}</small> */}
                        </div>
                        {/* <div className="col-sm-3">
                            <Rating defaultValue={4}></Rating>
                        </div> */}
                        {/* <Card.Text>Email : westernpit@test.com.au</Card.Text> */}
                    </div>
                        {/* <Card.Title>Wstern Pit Shop Pty Ltd</Card.Title>
                        <Rating defaultValue={4}></Rating> */}
                    <div className="card-bottom">
                        <div className="col-sm-7" style={{marginTop:'15px'}}>
                            {/* <div className="media-icon">
                                <div onClick={()=> {this.openInNewTab(tenants.webAddress ? tenants.webAddress : '')}} >
                                    <Web/>                            
                                </div>
                                <div onClick={()=> {this.openInNewTab(tenants.twitterAddress ? tenants.twitterAddress : '')}} >
                                    <Twitter/>
                                </div>
                                <div onClick={()=> {this.openInNewTab(tenants.facebookAddress ? tenants.facebookAddress : '')}} >
                                    <Facebook/>
                                </div>
                                <div onClick={()=> {this.openInNewTab('https://mail.google.com/mail/u/0/#inbox?compose=new')}} >
                                    <MailOutlineOutlined/>
                                </div>
                            </div> */}
                        </div>
                        <div className="card-bottom-btn">
                            <Button 
                                onClick={() => {handleTenantDetails(tenants)}}
                                variant="primary" 
                                text ={tenants && tenants.expireStatus ? 'Inquiry' :'Book Online'}
                            />
                        </div> 
                    </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

Cards.propTypes = {
    tenants: PropTypes.any,
    handleTenantDetails: PropTypes.func,
}

export default Cards;